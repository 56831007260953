import * as React from "react";
import { Button, Container, Form } from "react-bootstrap";
import { container, message, wrapper } from "./GuestPropertyView.module.scss";
import { Field } from "redux-form";

export const StyledContainer = (props: any) => {
    return (<Container className={container}>{props.children}</Container>);
};

export const Message = (props: any) => {
    return (<div className={message}>{props.children}</div>);
};

export const Wrapper = (props: any) => {
    return (<div className={wrapper}>{props.children}</div>);
};

const FormInput = (input: any, label: string, meta: {touched: boolean, error: string}, placeholder: string, type = 'text') => {    
    const className = `${meta.error && meta.touched ? 'error' : ''}`;
    return (
        <Form.Group className={className}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                type={type}
                {...input}
                autoComplete="on"
            />
            <Form.Control.Feedback type="invalid">
                {meta.error}
            </Form.Control.Feedback>
        </Form.Group>
    );    
};

export const GuestForm = (props: {isValid: boolean, submitted: boolean, submitting: boolean, handleSubmit: any}) => {
    if (props.submitted) {
      return (
        <React.Fragment>
          <h1>Thank You</h1>
          <p>Thank you for staying with us and we look forward to welcoming you back again soon.
          Your folio will be sent to the e-mail address on your reservation shortly.</p>
        </React.Fragment>
      );
    }
    else if (props.isValid) {
      return (
        <React.Fragment>
          <h1>Express Checkout</h1>
          <p>
            Please provide your room number to proceed with express checkout.
          </p>
          <Form onSubmit={props.handleSubmit}>
            <Field
              component={FormInput}
              type="text"
              name="RoomNumber"
              label="Room Number"
              disabled={props.submitting}
            />
            <Button type="submit" disabled={props.submitting}>
              {props.submitting ? "Please wait..." : "Submit"}
            </Button>
          </Form>
        </React.Fragment>
      );
    }
    return (<p>Sorry, this page isn\'t available. The link you followed may be broken, or the page may have been removed.</p>);
  };